<template>
  <v-container class="reset-password-container">
    <CategoryTitle :category="category" />
    <v-card flat>
      <v-card-title class="text-h4">
        {{ $t("resetPassword.insertNewPsw") }}
      </v-card-title>
      <v-card-text class="pb-0">
        <v-form
          ref="form"
          class="reset-password"
          v-model="valid"
          :lazy-validation="lazy"
        >
          <v-text-field
            v-model="password"
            label="Password *"
            :append-icon="showPassword ? '$eye' : '$eyeOff'"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordRules"
            @click:append="toggleShowPassword"
            required
            outlined
            dense
          ></v-text-field>
          <v-text-field
            v-model="passwordConfirm"
            label="Conferma Password *"
            :append-icon="showPassword ? '$eye' : '$eyeOff'"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordConfirmRules"
            @click:append="toggleShowPassword"
            required
            outlined
            dense
            clearable
          ></v-text-field>
        </v-form>
        <ResponseMessage :response="response" />
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="handleResetPassword"
          class="reset-button"
          color="primary"
          min-width="200"
          large
          depressed
          :disabled="!valid || password == null || password != passwordConfirm"
          >{{ $t("login.submitResetPassword") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<style lang="scss">
.reset-password-container {
  .reset-password {
    width: 350px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      width: 100%;
    }
  }
}
</style>
<script>
import ResponseMessage from "@/components/ResponseMessage";
import CategoryTitle from "@/components/category/CategoryTitle";

import categoryMixin from "~/mixins/category";

import userService from "~/service/userService";

import { mapActions } from "vuex";

import {
  requiredValue,
  minLength,
  minCapitalCharacter,
  minSymbolCharacter
} from "~/validator/validationRules";

export default {
  name: "ResetPassword",
  components: { ResponseMessage, CategoryTitle },
  mixins: [categoryMixin],
  data() {
    return {
      showPassword: false,
      password: null,
      passwordConfirm: null,
      valid: true,
      lazy: true,
      passwordRules: [
        requiredValue("Digitare la password"),
        minLength(8, "La password deve essere lunga almeno 8 caratteri"),
        minCapitalCharacter(),
        minSymbolCharacter()
      ],
      passwordConfirmRules: [
        v => !!v || "Confermare la password",
        v => v === this.password || "Le password non coincidono"
      ],
      errors: [],
      invalid: null,
      token: null,
      email: null,
      response: {},
      showForm: true
    };
  },
  methods: {
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    },
    ...mapActions({ loadCart: "cart/loadCart" }),
    async handleResetPassword() {
      try {
        this.loading = true;
        const response = await userService.resetPassword(
          this.email,
          this.token,
          this.password
        );
        this.response = response.response;
        if (response.response.status == 0) {
          this.loadCart();
          // this.$router.push({ name: "ResetPasswordCompleted" });
        }
      } catch (err) {
        if (err.response) {
          this.response = err.response.data.response;
        }
      } finally {
        this.loading = false;
        this.showForm = false;
      }
    },
    getQueryParams() {
      this.token = this.$route.query.token;
      this.email = this.$route.query.email;
    }
  },
  mounted() {
    this.getQueryParams();
  }
};
</script>

<style></style>
